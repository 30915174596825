class ZIDXAccountForgotPasswordCheckEmail implements ZIDXPage{
    constructor() {
    }
    getPageClass(){
        return "zidxAccountForgotPasswordCheckEmailContainer";
    }
    getMatchURL(){
        return "/forgot-password/check-email";
    }
    render(){
        // console.log("forgot password check email");
    }
}
